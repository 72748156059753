import { Controller } from 'stimulus';
import Rails from "@rails/ujs";
export default class extends Controller {
    static targets = ['form','order'];
   	static values = { submitted: Boolean, checkout: String }

    initialize() {
	  console.log("Stimulus");
	 }
    


    submit(event)
    {
    	let isValid = this.validateForm(this.formTarget);
    	if(isValid)
    	{
    		this.formTarget.submit();
    	}
    	else
    	{
    		this.submittedValue = true
    	}
    	
    }
    formIsChanged(event)
    {
    	if(this.submittedValue)
    	{
    		this.validateForm(this.formTarget);
    	}
    }

    selectChanged(event)
    {
    	console.log("Form changed",event.target.value);
    	Rails.ajax({
	      type: "post",
	      url: "/checkouts/update_country",
	      data: `checkout_token=${this.checkoutValue}&country=${event.target.value}`,
	      success: function(data) {
		    console.log(data.new_price);
		    console.log(document.getElementById("total-checkout-price"));
		    document.getElementById("total-checkout-price").textContent = data.new_price
		    document.getElementById("country-shipping-information").innerHTML = data.message 
		  },
		  error: function(data)
		  {
		  	console.log("Something went wrong!");
		  }
	    })
    }


    newOrder(event)
    {

    	this.orderTarget.submit();

    	return

    	console.log("Submit new order",document.getElementById("preview-picture").getAttribute('data-do-not-submit'));
    	var personError = (parseInt(document.getElementById("order_number_of_persons").value) == 0);
        console.log("NUmber of persons", personError);
        var uploadError = (document.getElementById("preview-picture").getAttribute('data-do-not-submit') == "true")
    	console.log("Upload error",uploadError);
    	console.log("src",document.getElementById("preview-picture").src != "")
    	if(document.getElementById("preview-picture").src != "" && !uploadError && !personError)
    	{
            console.log("Window.datalayer",window.dataLayer)

            //5 is the default item id
            var item_id = (window.localStorage.getItem("miroar_item") != null)  ? window.localStorage.getItem("miroar_item") : 5;

            window.dataLayer.push({
                'event': 'add_to_cart',
                'value': 70,
                'items' : [{
                    'id': item_id,
                    'google_business_vertical': 'retail'
                }]});
    	    this.orderTarget.submit();
    	}
    	else
    	{
            if(document.getElementById("preview-picture").src == "" || uploadError)
            {
                //Show error message
                document.getElementById("upload-missing-error").style.display = "block";
                //Switch to the first tab
                document.getElementById("first_tab").click();
            }
            if(personError)
            {
                 //Show error message
                document.getElementById("person-missing-error").style.display = "block";
                //Switch to the first tab
                document.getElementById("first_tab").click();
            }
    		
    	}

    }

    validateForm() {

    	

	    let isValid = true;


	    function validatePresence(value,input)
		{
			return !(input === "")
		};

		function validateNumeric(value,input)
		{
			console.log("Validate numeric",input);
			const re = /^[0-9]*$/
			return re.test(String(input).toLowerCase());
		};

		function validateEmail(value,input)
		{
			const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    		return re.test(String(input).toLowerCase());
    	};

    	function validateCheckbox(value,input)
		{
    		return document.getElementById("checkbox-2").checked;
    	};

		var validator = {"validatePresence":[validatePresence, "Dieses Feld wird benötigt."]}

		validator["numeric"] = [validateNumeric,"Die Eingabe darf nur aus Zahlen bestehen."]
		validator["email"] = [validateEmail,"Bitte gib eine gültige E-Mail ein."]
		validator["checked"] = [validateCheckbox,"Du musst unsere AGB bestätigen."]
	    

	    let rules =  {
                    "checkout[user_attributes][first_name]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][last_name]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][email]": {
                    	"validatePresence": true,
                    	"email": true
                    },
                    "checkout[user_attributes][phone_number]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][street]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][housenumber]": {
                    	"validatePresence": true,
                    },
                    "checkout[user_attributes][zip_code]": {
                    	"validatePresence": true,
                    	"numeric": true
                    },
                    "checkout[user_attributes][city]": {
                    	"validatePresence": true,
                    },
                    "checkbox-2": {
                    	"checked": true,
                    }
        }

        var validation_result = true
        for (var key in rules) {
		    console.log("Key",key)
		    let field = this.formTarget.querySelector(`input[name='${key}']`);
		    console.log("Current input",field);
		    console.log("Current input",);
		    var current_value = field.value.trim()
		    var validations = rules[key]
		    for (key in validations)
		    {
		    	console.log("Validation keys",key);
		    	console.log("Validation value",validations[key]);
		    	var validation_function = validator[key][0];
		    	console.log("Problem",validation_function(validations[key],current_value));
		    	if(!validation_function(validations[key],current_value))
		    	{
		    		console.log("PRint message",validator[key][1])
		    		validation_result = false;
		    		var node = field.parentNode.querySelector(".label_error")
		    		
		    		if(node != null)
		    		{
		    			node.textContent = validator[key][1]
		    		}
		    		else
		    		{
		    			document.getElementById("checkbox-label").style.color = "red";
		    		}
		    		break;
		    	}
		    	else
		    	{	
		    		var node = field.parentNode.querySelector(".label_error")
		    		
		    		if(node != null)
		    		{
		    			node.textContent = ""
		    		}
		    		else
		    		{
		    			document.getElementById("checkbox-label").style.color = "black";
		    		}
		    	}
		    }
		    console.log("Test",field.parentNode.querySelector(".error_label"));
		    console.log("Test",field.closest( ".error_label" ));
		    

		}

		return validation_result;

	    // // Tell the browser to find any required fields
	    // let requiredFieldSelectors = 'textarea:required, input:required';
	    // let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

	    // requiredFields.forEach((field) => {
	    //   // For each required field, check to see if the value is empty
	    //   // if so, we focus the field and set our value to false
	    //   if (!field.disabled && !field.value.trim()) {
	    //     field.focus();

	    //     isValid = false;
	    //   }
	    // });

	    return isValid;
	}


	//Editor functionality
	headlineChanged(event)
    {	
    	

    	if(event.target.value != "")
    	{
    		console.log("Show text preview");
    		document.getElementById("imageBackground").classList.remove('without_text');
    		document.getElementById("text_preview").classList.remove('hidden');
    	}
        else
        {
        	console.log("Hide text preview");
        	document.getElementById("imageBackground").classList.add('without_text');
        	document.getElementById("text_preview").classList.add('hidden');
        }

        var length = 35 - event.target.value.length;
        if(length >= 0)
        {
            document.getElementById("main_title_chars").innerHTML = `Noch ${length} von 35 Zeichen verbleibend`;

            if(length==0)
            {
                document.getElementById("main_title_chars").classList.add("reached");
            }
            else
            {
                document.getElementById("main_title_chars").classList.remove("reached");
            }

        }
        else
        {
            document.getElementById("main_title_chars").classList.add("reached");
            event.target.value = event.target.value.slice(0,35);
        }

        document.getElementById("main_customer_title").innerHTML = event.target.value;

    }

    //Editor functionality
	subtitleChanged(event)
    {	
    	
        var length = 35 - event.target.value.length;
        if(length >= 0)
        {
            document.getElementById("sub_title_chars").innerHTML = `Noch ${length} von 35 Zeichen verbleibend`;

            if(length==0)
            {
                document.getElementById("sub_title_chars").classList.add("reached");
            }
            else
            {
                document.getElementById("sub_title_chars").classList.remove("reached");
            }

        }
        else
        {
            document.getElementById("sub_title_chars").classList.add("reached");
            event.target.value = event.target.value.slice(0,35);
        }

        document.getElementById("main_customer_subtitle").innerHTML = event.target.value;

    }


    openDropdown(event)
    {
    	console.log("OPen dropdown",this);
    	this.element.querySelector('.custom-select').classList.toggle('open');
    }
    changeFont(event)
    {
    	console.log("Change font",this);
    	console.log("Change event",event.target.classList);
    	if (!event.target.classList.contains('selected')) {
               document.querySelectorAll('.font_selection_button').forEach(function(button)
                {
                    button.classList.remove('selected');
                });
               event.target.classList.add('selected');
            console.log(event.target.getAttribute("data-value-font"));
            document.getElementById("main_customer_title").style.fontFamily = event.target.getAttribute("data-value-font");
            document.getElementById("main_customer_subtitle").style.fontFamily = event.target.getAttribute("data-value-font");
            document.getElementById("main_customer_subtitle").style.fontWeight = event.target.getAttribute("data-value-weight");
            document.getElementById("font_family").value = event.target.getAttribute("data-value-font");
        }
    }


}