


$( document ).on('turbolinks:load', function() {





  $('input[type=radio]').change(function(){
      console.log("Input radio pressed",$(this).attr('name'));
      console.log($(this).parent().parent());
      $(this).parent().parent().find(".selection").removeClass("selected");
      var button = $(this).next();
      button.addClass("selected");
  });
  
   $('input[name="order[background_color]"]').change(function(){
      var button = $(this).next();
      $("#imageBackground").css('cssText', 'background-color: ' + button.css("background-color") + "!important;");
  });
  
  


});
