import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   

    static targets = ['couponOrder'];
  
    initialize() {
      console.log("Coupon stimulus controller");

       
        

    }

    selectCouponType(event)
    {

      $(event.currentTarget).parent(".vertical_select_wrapper").children(".vertical_select_option").removeClass("selected");
      $(event.currentTarget).parent(".vertical_select_wrapper").find(".option_select_indicator").removeClass("selected");
      $(event.currentTarget).find(".option_select_indicator").addClass("selected");
      $(event.currentTarget).addClass("selected");

      $("#coupon_preview").attr("src",$(event.currentTarget).data("coupon-preview"));


      $("#coupon_design").val($(event.currentTarget).data("coupon-design"));

      console.log($(event.currentTarget).data("coupon-preview"));

    }

    changeCouponValue(event)
    {
      console.log("Coupon changed",event);

      var couponValueField = event.currentTarget;

      couponValueField.value = couponValueField.value.replace(/\D/g,'');

      if(couponValueField.value.length==1)
      {
        couponValueField.value = "0,0" + couponValueField.value
      }
      else if(couponValueField.value.length==2)
      {
        couponValueField.value = "0," + couponValueField.value
      }
      else if(couponValueField.value.length== 4)
      {
        if(couponValueField.value.slice(0,2) == "00")
        {
          couponValueField.value = "0," + couponValueField.value.slice(2);
        }
        else if(couponValueField.value.slice(0,1) == "0")
        {
          couponValueField.value = couponValueField.value.slice(1,2) + "," + couponValueField.value.slice(2);
        }
        else
        {
          couponValueField.value = couponValueField.value.slice(0,2) + "," + couponValueField.value.slice(2);
        }
      }
      else
      {
        couponValueField.value = couponValueField.value.slice(0,couponValueField.value.length-2) + "," + couponValueField.value.slice(couponValueField.value.length-2);
      } 


      $(".add_to_cart_price").text(couponValueField.value + " €");

      this.validateCouponValue();

    }


    newCouponOrder(event)
    {
      console.log("New coupon order");

      let isValid = this.validateCouponValue();
      if(isValid)
      {
        this.couponOrderTarget.submit();
      }
  
      
    }

    validateCouponValue()
    {

      let absolute_amount = parseInt(document.getElementById("coupon_absolute_amount").value.replace(/\D/g,''));
      if(absolute_amount < 2500 || absolute_amount > 100000)
      {
        $("#value-too-low").show();
        return false;
      }
      else
      {
        $("#value-too-low").hide();
        return true;
      }

    }





}