import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import ActiveStorageUpload from '@michaeldopfer/uppy-activestorage-upload'

export default class extends Controller {
   
   	static values = { submitted: Boolean, checkout: String, uploadCount: Number }

    static targets = ["moreThanThree", "afterUploadModal", "moreThanThreeButton", "lessThanThreeButton", "imageProcessing", "imageQualityCheckButton"]
 
    initialize() {
      console.log("Order stimulus controller", this.uploadCountValue);

       
          
        const uppyElement = document.querySelector('[data-uppy]');
        this.setupUppy(uppyElement);
        

    }

    setupUppy(element)
    {
        console.log("Setup uppy new");

      

        

        let form = element.closest('form')
          let direct_upload_url = document.querySelector("meta[name='direct-upload-url']").getAttribute("content")
          let field_name = element.dataset.uppy
          const csrfToken = document.querySelector('meta[name="csrf-token"]').content
          



          let uppy = new Uppy({
            autoProceed: true,
            allowMultipleUploads: false,
            logger: Uppy.debugLogger,
            restrictions: {
                maxFileSize: 30000000,
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*']
            }
          })

          console.log("Uppy",uppy);

          uppy.use(ActiveStorageUpload, {
            directUploadUrl: direct_upload_url,
            headers: { "X-CSRF-Token": csrfToken }
          })


           let preview = element.querySelector('[data-behavior="uppy-preview"]')
           var buttonText = "Bild auswählen"
           if (preview.src != "") {
              console.log("Preview",preview);
               buttonText = "Bild ändern"
            }
          

           uppy.use(FileInput, {
            target: '#uppy-root',
             pretty: true,
             locale: {
              strings: {
                chooseFiles: buttonText
              }
            }
          })

           uppy.on('upload', (data) => {
              $(".progress_bar_progress_wrapper").removeClass("hidden");
              preview.src = "";
           });

          uppy.on('upload-success', (file, response) => {
            console.log("Upload successful");
            preview.setAttribute('data-do-not-submit', false);

           


          });

          uppy.on('upload-error', (file, error, response) => {
            console.log("Error", error);
            $(".progress_bar_progress_wrapper").addClass("hidden");
            window.alert('Es ist ein Fehler aufgetreten. Bitte versuche es erneut. Error: ' + error);
            preview.setAttribute('data-do-not-submit', true);
          })

           uppy.on('upload-progress', (file, progress) => {
              // file: { id, name, type, ... }
              // progress: { uploader, bytesUploaded, bytesTotal }
              var percentage = Math.round((progress.bytesUploaded/progress.bytesTotal) * 100)
              document.getElementById("progress-counter").innerHTML = `${percentage}%`
              document.getElementById("progress-bar").style.width = `${percentage}%`
              console.log("CustomerProgress",percentage)
            });
          

           

           $(".uppy-Dashboard-poweredBy").hide();
           $(".uppy-Dashboard-inner").css("background-color","transparent");
           $(".uppy-FileInput-btn").addClass("upload-button");
           
         

          uppy.on('complete', (result) => {
            // Rails.ajax
            // or show a preview:
            element.querySelectorAll('[data-pending-upload]').forEach(element => element.parentNode.removeChild(element))

            result.successful.forEach(file => {
              this.appendUploadedFile(element, file, field_name)
              this.setPreview(element, file)
            })

            uppy.reset()
          });

    }

    appendUploadedFile(element, file, field_name) {
      const hiddenField = document.createElement('input')

      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', field_name)
      hiddenField.setAttribute('data-pending-upload', true)
      hiddenField.setAttribute('value', file.response.signed_id)

      element.appendChild(hiddenField)
    }

    setPreview(element, file) {
      let preview = element.querySelector('[data-behavior="uppy-preview"]');

      this.imageProcessingTarget.classList.remove("hidden");

      if (preview) {



        Rails.ajax({
            type: "post",
            url: "/order_preview_url",
            data: `key=${file.response.key}`,
            success: function(url) { 
              preview.src = url
              $(".progress_bar_progress_wrapper").addClass("hidden");
              this.imageProcessingTarget.classList.add("hidden");
              this.imageQualityCheckButtonTarget.classList.remove("hidden")

              this.uploadCountValue ++;

              //Only display the popup on the first upload
              if(this.uploadCountValue == 1)
              {
                 setTimeout(function(){this.afterUploadModalTarget.classList.remove("hidden")}.bind(this), 2000);
              }

             
          
            }.bind(this),
            error: function(data)
            {
              console.log("Something went wrong!");
              preview.src = "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSpj0DBTVsaja01_xWh37bcutvpd7rh7zEd528HD0d_l6A73osY"
              $(".progress_bar_progress_wrapper").addClass("hidden");
              this.imageProcessingTarget.classList.add("hidden");
            }.bind(this)
          });

        $("#upload-missing-error").hide();
      }
    }

    showFrameSelection(event)
    {
      console.log("Show frame selection", this.frameSelectionTarget);
      this.frameSelectionTarget.style.display = "block";

       // Hide the other selection modal
       this.printNumberSelectionTarget.style.display = "none"; 

    }

    changeFrame(event)
    {
      
      console.log("Change frame2", $(event.currentTarget).find(".frame_select_heading")[0].innerHTML);
  

      this.currentFrameSelectionTarget.innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");
      var newFrameValue = $(event.currentTarget).find(".frame_select_heading").data("frame-value")
      $('#order_frame').val(newFrameValue);
      this.frameSelectionTarget.style.display = "none";


      //Change preview
      $("#frame_preview").removeClass("black_frame white_frame natural_frame").addClass(newFrameValue);

      if(newFrameValue == "no_frame")
      {
        
      }


      this.calculatePrice()

    }



    showPrintNumberSelection(event)
    {
      console.log("Show frame selection 1", this.frameSelectionTarget);
      console.log("Show frame selection 2", this.currentFrameSelectionTarget);
      console.log("Show print number selection", this.printNumberSelectionTarget);
      this.printNumberSelectionTarget.style.display = "block";

      // Hide the other modal 
      this.frameSelectionTarget.style.display = "none";
    }
    

    lessThanThreeObjects(event)
    {
      console.log("Event",event);

      this.moreThanThreeTarget.classList.add("hidden");

      document.querySelectorAll(".preselect_button").forEach((button) => {
        button.classList.remove("selected");
      }); 
      this.lessThanThreeButtonTarget.classList.add("selected");

      $("#order_number_of_persons").val(0);


      this.calculatePrice();

    }

    moreThanThreeObjects(event)
    {
      console.log("Event",event);

      this.moreThanThreeTarget.classList.remove("hidden");

      $("#persons_counter").text(4);

      document.querySelectorAll(".preselect_button").forEach((button) => {
        button.classList.remove("selected");
      }); 
      this.moreThanThreeButtonTarget.classList.add("selected");

      $("#order_number_of_persons").val(4);
      this.calculatePrice();

    }

    changePrintNumber(event)
    {
      
      console.log("Change frame2", $(event.currentTarget));
  

      this.currentPrintNumberSelectionTarget.innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");
      var newFrameValue = $(event.currentTarget).find(".frame_select_heading").data("print-number-value")
      $('#order_number_of_prints').val(newFrameValue);

      this.printNumberSelectionTarget.style.display = "none";

      this.calculatePrice()

    }




    sizeChanged()
    {
      console.log("Size changed");



      this.calculatePrice()
    }




    calculatePrice()
    {

      let numberOfPersons = $("#order_number_of_persons").val();
      let backgroundColor = $('#order_background_color').val();


      Rails.ajax({
            type: "post",
            url: "/calculate_price",
            data: `number_of_persons=${numberOfPersons}&background_color=${backgroundColor}`,
            success: function(result) { 
                console.log("New price",result)
                this.adjustPrice(result);
            }.bind(this),
            error: function(data)
            {
              console.log("Something went wrong!");
            }
          })
    }

    adjustPrice(result)
    {

      $(".add_to_cart_price").text(result.new_price);
      $("#additional_price").text(result.price_increase);
     

      if(result.price_increase != "+ 0 €")
      {
       $("#additional_price").css("display","block");
      }
      else
      {
        $("#additional_price").css("display","none");
      }
    }


    decreaseNumberOfPersons(event)
    {
      console.log("Decrease");
      var currentValue = parseInt($("#order_number_of_persons").val());
      currentValue = currentValue - 1; 
      if(currentValue<4)
      {

       //switch to less than 4
       currentValue = 0;
       this.lessThanThreeObjects();
        
      }
      else if(currentValue==4)
      {
       $("#decrease_number_of_persons").addClass("non_active");
       // $("#person-missing-error").show();
      }
      $("#order_number_of_persons").val(currentValue);
      $("#persons_counter").text(currentValue);
      this.calculatePrice()

    }

    increaseNumberOfPersons(event)
    {
     var currentValue = Math.max(4,parseInt($("#order_number_of_persons").val()));
     currentValue = currentValue + 1; 
     $("#order_number_of_persons").val(currentValue);
     $("#persons_counter").text(currentValue);
     $("#decrease_number_of_persons").removeClass("non_active");
     //$("#person-missing-error").hide();
     this.calculatePrice()
    }

    changeMetal(event)
    {
      var selected_background_color = event.currentTarget.dataset.valueMetal;
      $("#order_background_color").val(selected_background_color);

      document.querySelectorAll(".select_metal_configuration").forEach(element => {
             element.classList.remove("selected");
      });
      console.log(event.currentTarget.querySelector(".select_metal_configuration"));
      event.currentTarget.querySelector(".select_metal_configuration").classList.add("selected");

      this.calculatePrice();

    }

    showFrameInfo()
    {
      console.log("Show frame info");
      this.showModal("frames");
    } 

    showPersonsInfo()
    {
      console.log("Show persons info");
      this.showModal("persons");
    }

    showUploadInfo()
    {
      console.log("Show upload info");
      this.showModal("upload");
    }

    showAdditionalPrintsInfo()
    {
      console.log("Show additional prints info");
      this.showModal("prints");

     

    }
  

    showModal()
    {

       this.afterUploadModalTarget.classList.remove("hidden");

    }

    closeModal()
    {
      console.log("Close modal");
       this.afterUploadModalTarget.classList.add("hidden");
    }
}