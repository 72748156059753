import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   
   static targets = ["modalContent"]

 
    static values = { token: String, messageType: String }
   
    initialize() {
      console.log("Admin Checkout controller");

    }

    editCustomer()
    {
      this.showModal();

      Rails.ajax({
        type: "get",
        url: `/checkouts/${this.tokenValue}/get_user_edit_modal`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.user_edit_form;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });

    }


    showImage()
    {
      this.showModal();


      console.log("Order to show image", $(event.currentTarget).data("order-token"));
      var orderToken = $(event.currentTarget).data("order-token")

      Rails.ajax({
        type: "get",
        url: `/orders/${orderToken}/get_full_resolution_image`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.full_resolution_image;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });

    }

    sendDraft(event)
    {

      let orderToken = $(event.currentTarget).data("checkout-token");
      this.showModal();
      Rails.ajax({
        type: "get",
        url: `/checkouts/${orderToken}/get_checkout_draft_sent_modal`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.checkout_draft_form;

        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }


    //This method displays a modal that enables the user to send out the digital version of the orders with one click
    sendDigitalVersionModal(event)
    {
      let orderToken = $(event.currentTarget).data("checkout-token");
      this.showModal();
      Rails.ajax({
        type: "get",
        url: `/checkouts/${orderToken}/get_checkout_digital_version_sent_modal`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.checkout_digital_version_form;

        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }



    //Draft selection changed
    draftSelectionChanged()
    {
      this.updateMessage();
    }

  

    changeMessageType()
    {
      this.updateMessage();
    }

    changeMessengerType()
    {
      this.updateMessage();
    }

    newDraftSendTask()
    {

      if(this.getNumberOfSelectedDrafts() == 0)
      {
        window.alert("Bitte wähle mindestens einen Entwurf für den Versand aus.")
      }
      else if(!this.message_type_selected())
      {
        window.alert("Bitte wähle die Nachrichten Art aus.")
      }
      else
      {

        console.log("Test",document.getElementById("draft_send_task_form"));



        document.getElementById("new_draft_send_task").submit();
      }

    }


    //This methods 
    newCustomerMessage()
    {

      document.getElementById("new_draft_send_task").submit();
    }



    //Check whether a message type has been selected
    message_type_selected()
    {
      if(document.querySelector('input[name="draft_send_task[message_type]"]:checked') == null)
      {
        return !(document.getElementById("draft_send_task_message_type").value == null)
      }
      return !(document.querySelector('input[name="draft_send_task[message_type]"]:checked') == null)
    }

    updateMessage()
    {
      console.log("Update message");
      var userName = document.getElementById("draft_send_task_additional_information[customer_name]").value
      var employeeName = document.getElementById("draft_send_task_additional_information[employee_name]").value

      var returningCustomer = false;

      if(document.getElementById("draft_send_task_returning_customer"))
      {
        returningCustomer = (document.getElementById("draft_send_task_returning_customer").value == "true")
      }

      console.log("Returning customer", returningCustomer);


      var messageType; 
      if(document.querySelector('input[name="draft_send_task[message_type]"]:checked') == null)
      {
        if(document.getElementById("draft_send_task_message_type").value == null)
        {
          //Both message types are not defined, return and do not generate any text preview
          return;
        }
        else
        {
          //Generate the text for the digital file message, no other message types are possible there -> No dropdown or radio button
          messageType = document.getElementById("draft_send_task_message_type").value
        }
      }
      else
      {
        //Generate the text for the normal text
        messageType = document.querySelector('input[name="draft_send_task[message_type]"]:checked').value;
      }

      let messengerType = document.querySelector('input[name="draft_send_task[messenger_type]"]:checked').value;
      let numberOfDrafts = this.getNumberOfSelectedDrafts();

      if(numberOfDrafts == 0)
      {
        document.getElementById("draft_send_task_additional_information[main_message]").value = "Wähle mindestens einenen Entwurf aus";
        document.getElementById("draft_send_task_additional_information[upselling_message]").value = "Wähle mindestens einenen Entwurf aus";
        return 
      }

      console.log("PRoblem",userName,employeeName,messageType,messengerType, numberOfDrafts);

      if(messengerType == "whatsapp")
      {
        this.generateWhatsAppTemplateTexts(messageType,numberOfDrafts, userName, employeeName, returningCustomer)
      }
      else
      {
        this.generateEmailTemplateTexts(messageType,numberOfDrafts, userName, employeeName)
      }

      //Do nothin more in case it is about generating a digital file 
      if(messageType == "digital_file")
      {
        return 
      }
      

      //Do not submit an upselling message when this checkbox is not checked or disabled
      if(document.getElementById("draft_send_task_upselling").checked == false)
      {
        document.getElementById("draft_send_task_additional_information[upselling_message]").disabled = true
      }

      if(returningCustomer)
      {
        document.getElementById("draft_send_task_additional_information[upselling_message]").value = `Unser 50% Rabattangebot auf jeden weiteren Druck gilt natürlich weiterhin 😊 LG ${employeeName}`
      }
      else
      {
        document.getElementById("draft_send_task_additional_information[upselling_message]").value = `Falls Du noch Interesse an weiteren Drucken vom selben Motiv hast dann gib einfach Bescheid. Jeder weitere Druck ist immer 50% günstiger da wir dabei keinen zusätzlichen Zeichenaufwand haben 😊 LG ${employeeName}`
      }
      
     
    }


    //This method is called when the custom message dropdown changes

    customMessageChanged(event)
    {
      
      //Update the message type after the dropdown has changed
      let messageType =  document.getElementById("draft_send_task_message_type").value
      var userName = document.getElementById("draft_send_task_additional_information[customer_name]").value
      var employeeName = document.getElementById("draft_send_task_additional_information[employee_name]").value
      
      console.log("User",userName);
       console.log("User",messageType);

      this.generateWhatsAppTemplateTexts(messageType,0, userName, employeeName,false)

    }




    generateWhatsAppTemplateTexts(messageType,numberOfDrafts, userName, employeeName,returningCustomer)
    {
      if(messageType == "initial_draft_message")
      {
          // Activate upselling checkbox and field
          document.getElementById("draft_send_task_upselling").disabled = false;
          document.getElementById("draft_send_task_additional_information[upselling_message]").disabled = false;

          var main_message = ""; 
          if(numberOfDrafts == 1)
          {
            if(returningCustomer)
            {
              main_message = `Ganz lieben Dank Dir für Deine weitere Bestellung, das hat uns echt riesig gefreut 😍 Wir haben deinen Miroar Entwurf wieder fertig gezeichnet, gib mir einfach Bescheid, ob wir ihn Dir so zusenden dürfen 🤗📦`
            }
            else
            {
              main_message = 'Wir haben deinen Miroar Entwurf fertig gezeichnet, gib mir einfach Bescheid, ob wir ihn Dir so zusenden dürfen 🤗📦'
            }
          }
          else if(numberOfDrafts == 2)
          {
            if(returningCustomer)
            {
              main_message = `Ganz lieben Dank Dir für Deine weitere Bestellung, das hat uns echt riesig gefreut 😍 Wir haben den Miroar Entwurf jetzt wie gewohnt einmal mit und einmal ohne Augen gezeichnet, gib mir einfach Bescheid, welche Variante wir Dir zusenden dürfen 🤗📦 `
            }
            else
            {
              main_message = 'Wir haben den Miroar Entwurf jetzt einmal mit und einmal ohne Augen gezeichnet, gib mir einfach Bescheid, welche Variante wir Dir zusenden dürfen 🤗📦 ';
            }
          }
          else if(numberOfDrafts > 2)
          {
            if(returningCustomer)
            {
              main_message = `Ganz lieben Dank Dir für Deine weitere Bestellung, das hat uns echt riesig gefreut 😍 Wir haben Deine Miroar Entwürfe jetzt wie gewohnt jeweils einmal mit und einmal ohne Augen gezeichnet, gib mir einfach Bescheid, welche Varianten wir Dir zusenden dürfen 🤗📦 `
            }
            else
            {
              main_message = 'Wir haben Deine Miroar Entwürfe jetzt jeweils einmal mit und einmal ohne Augen gezeichnet, gib mir einfach Bescheid, welche Varianten wir Dir zusenden dürfen 🤗📦';
            }
          }
          
          if(this.greetingRequired())
          {
            main_message = this.addGreetingToMessage(userName,"",employeeName,main_message)
          }

          document.getElementById("draft_send_task_additional_information[main_message]").value = main_message;

          


      }
      else if(messageType == "adjustment_draft_message")
      {

         // Deactivate upselling checkbox and field
        document.getElementById("draft_send_task_upselling").disabled = true;
        document.getElementById("draft_send_task_additional_information[upselling_message]").disabled = true;
  
        var adjustment_message = ""; 
        var intro = "";
        if(numberOfDrafts == 1)
        {
          adjustment_message = 'Dürfen wir ihn Dir in dieser angepassten Variante zusenden?'
          intro = "wir haben den Miroar Entwurf fertig überarbeitet,"
        }
        else if(numberOfDrafts > 1)
        {
          adjustment_message = 'Dürfen wir Dir die überarbeiteten Miroar Entwürfe in diesen angepassten Varianten zusenden?';
          intro = "wir haben Deine Miroar Entwürfe fertig überarbeitet,"
        }
        
        
        if(this.greetingRequired())
        {
          adjustment_message = this.addGreetingToMessage(userName, intro, employeeName, adjustment_message)
        }

        document.getElementById("draft_send_task_additional_information[main_message]").value = adjustment_message;

      }
      else if(messageType == "bad_quality")
      {
        document.getElementById("individual_message_container").style.display = "none";
        document.getElementById("template_message_preview").innerHTML = `Hallo ${userName}, vielen Dank für Deine Bestellung bei uns 😍 Uns ist aufgefallen, dass die Qualität Deines hochgeladenen Bildes sehr niedrig ist, was uns das genaue Zeichnen sehr schwierig macht 🙈 Deshalb würde es uns sehr helfen, falls Du uns das Bild oder ein anderes in besserer Qualität zukommen lassen könntest. Wir freuen uns auf Deine Rückmeldung 🤗 Liebe Grüße, ${employeeName}`
      }
      else if(messageType == "payment_issues")
      {
         document.getElementById("individual_message_container").style.display = "none";
         document.getElementById("template_message_preview").innerHTML = `Hallo ${userName}, hier ist  ${employeeName} von Miroar.de 🤗  Unser System hat uns soeben benachrichtigt, dass die Bezahlung Deiner Bestellung nicht abgeschlossen wurde 🙈  Solltest Du noch Beratung bei der Auswahl des Bildes benötigen, kannst Du dich jederzeit an uns hier im Chat wenden 👍  Solltest Du kein Interesse mehr an einem Miroar Bild haben, gib uns bitte ebenfalls kurz Bescheid, dann löschen wir Deinen Auftrag aus unserem System 🤗  Liebe Grüße, ${employeeName}`
      }
      else if(messageType == "custom_message")
      {
        document.getElementById("individual_message_container").style.display = "block";
        document.getElementById("template_message_preview").innerHTML = `Hallo ${userName},  es gibt ein neues Update bezüglich Deiner Bestellung auf Miroar.de 🤗 Deshalb würden Dir gerne eine Nachricht senden 💪🏻 Klicke dazu einfach auf den Button unten. Liebe Grüße, ${employeeName} von Miroar.de`
      }
      else if(messageType == "digital_file")
      {

          var main_message = ""; 
          if(numberOfDrafts == 1)
          {
            main_message = 'Hier kommt noch wie versprochen die digitale Datei Deines Miroar Bildes. Unter nachfolgendem Link kannst Du sie ganz bequem herunterladen, der Link wird für 48h aktiv sein. {{1}} Solltest Du danach nochmal darauf zugreifen wollen, gib uns bitte einfach Bescheid.🤗'
          }
          else if(numberOfDrafts == 2)
          {
            main_message = 'Hier kommen noch wie versprochen die digitale Dateien Deines Miroar Bildes. Unter nachfolgendem Link kannst Du sie ganz bequem herunterladen, der Link wird für 48h aktiv sein. {{1}} Solltest Du danach nochmal darauf zugreifen wollen, gib uns bitte einfach Bescheid.🤗'
          }
          else if(numberOfDrafts > 2)
          {
             main_message = 'Hier kommen noch wie versprochen die digitalen Dateien Deiner Miroar Bilder. Unter nachfolgendem Link kannst Du sie ganz bequem herunterladen, der Link wird für 48h aktiv sein. {{1}} Solltest Du danach nochmal darauf zugreifen wollen, gib uns bitte einfach Bescheid.🤗'
          }
          
          
          document.getElementById("draft_send_task_additional_information[main_message]").value = main_message;


      }


    }


    generateEmailTemplateTexts(messageType,numberOfDrafts,userName, employeeName)
    {
        var message = ""

      if(messageType == "initial_draft_message")
      {

        if(numberOfDrafts == 1)
        {
           message = `Hallo ${userName},\n\nlieben Dank für Deine Bestellung auf Miroar.de. Wir haben Deinen Miroar Entwurf fertiggestellt, klicke einfach unten auf Datei 1 um den Entwurf anzuzeigen.`
           message += "\nTeile uns anschließend mit, ob wir die Variante so in den Druck geben dürfen und wie viele Examplare Du benötigst. "
        }
        else if(numberOfDrafts > 1)
        {
          message = `Hallo ${userName},\n\nlieben Dank für Deine Bestellung auf Miroar.de. Wir haben Deine Miroar Entwürfe fertiggestellt, klicke einfach unten auf Datei 1 und Datei 2 um jeweils den Entwurf mit und ohne Augen zu sehen.`
          message += "\nTeile uns anschließend mit, welche Variante wir für Dich in den Druck geben dürfen und wie viele Examplare Du benötigst. "
        }
        message += "\nJeder weitere Druck vom gleichen Motiv kostet nämlich nur 50% vom Ursprungspreis, da wir hier keinen weiteren Zeichenaufwand haben."
        message += `\n\nBeste Grüße \n${employeeName} von Miroar.de`
      }
      else if(messageType == "adjustment_draft_message")
      {
        message = `Hallo ${userName},\n\nlieben Dank für Deine Bestellung auf Miroar.de. Wir haben Deinen Miroar Entwurf fertig überarbeitet, klicke einfach wie gewohnt unten auf Datei 1 um den Entwurf zu sehen.`
        message += "\nDürfen wir die angepasste Variante so für Dich in den Druck geben?"
        message += `\n\nBeste Grüße \n${employeeName} von Miroar.de`

      }
      else if(messageType == "digital_file")
      {

          var main_message = ""; 
          if(numberOfDrafts == 1)
          {
            message = `Hallo ${userName},\n\nHier kommt noch wie versprochen die digitale Datei Deines Miroar Bildes. Unter nachfolgendem Link kannst Du sie ganz bequem herunterladen, der Link wird für 48h aktiv sein. {{1}} Solltest Du danach nochmal darauf zugreifen wollen, gib uns bitte einfach Bescheid.🤗`
            message += `\n\nBeste Grüße \n${employeeName} von Miroar.de`
          }
          else if(numberOfDrafts == 2)
          {
            message = `Hallo ${userName},\n\nHier kommt noch wie versprochen die digitale Datei Deines Miroar Bildes. Unter nachfolgendem Link kannst Du sie ganz bequem herunterladen, der Link wird für 48h aktiv sein. {{1}} Solltest Du danach nochmal darauf zugreifen wollen, gib uns bitte einfach Bescheid.🤗`
            message += `\n\nBeste Grüße \n${employeeName} von Miroar.de`
          }
          else if(numberOfDrafts > 2)
          {
            message = `Hallo ${userName},\n\nHier kommt noch wie versprochen die digitale Datei Deines Miroar Bildes. Unter nachfolgendem Link kannst Du sie ganz bequem herunterladen, der Link wird für 48h aktiv sein. {{1}} Solltest Du danach nochmal darauf zugreifen wollen, gib uns bitte einfach Bescheid.🤗`
            message += `\n\nBeste Grüße \n${employeeName} von Miroar.de`
          }
          


      }
      if(document.getElementById("draft_send_task_upselling")!=null)
      {
        document.getElementById("draft_send_task_upselling").disabled = true;
        document.getElementById("draft_send_task_additional_information[upselling_message]").disabled = true;
      }

      document.getElementById("draft_send_task_additional_information[main_message]").value = message
     
    }


    sendCustomerMessage()
    {
      console.log("Send customer a new message");

      this.showModal();
      Rails.ajax({
        type: "get",
        url: `/checkouts/${this.tokenValue}/get_checkout_user_message_form`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.checkout_user_message_form;

        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });

    }


    //Check whether a template message will be necessary first. If the template is necessary, no greeting will be necessary in the main message
    greetingRequired()
    {
      console.log("Check greeting",(document.getElementById("template_required") == null))
      return document.getElementById("template_required") == null;
    }

    getNumberOfSelectedDrafts()
    {
      return document.querySelectorAll('input[name="draft_send_task[file_data][]"]:checked').length;
    }

    addGreetingToMessage(userName,intro, employeeName,message)
    {
      var greeting_message = `Hallo ${userName}, `+ intro + message.charAt(0).toLowerCase() + message.slice(1)
      if(!this.withUpselling())
      {
        console.log("Problem",greeting_message);
        console.log("Problem",intro,message);
        return  greeting_message + ` LG ${employeeName}`;
      }
      return greeting_message
    }


    closeModal()
    {
      console.log("Close modal");
      document.getElementById("modal").classList.remove("slide_in");
      setTimeout(function(){
          document.getElementById("modal_background").classList.add("hidden");
      }, 100);
    }

    withUpselling()
    {
      console.log("Disabled", document.getElementById("draft_send_task_upselling").disabled);
      if(document.getElementById("draft_send_task_upselling").disabled)
      {
        console.log("Disabled");
        return false;
      }

      return document.getElementById("draft_send_task_upselling").checked ;
    }

    addOrder()
    {
      this.showModal();
      Rails.ajax({
        type: "get",
        url: `/checkouts/${this.tokenValue}/get_checkout_order_edit_modal`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.order_edit_form;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }


    showModal()
    {
      this.modalContentTarget.style.display = "none";
      document.getElementById("modal_background").classList.remove("hidden");
      setTimeout(function(){
          document.getElementById("modal").classList.add("slide_in");
      }, 100);
    }

    moveOrderModal()
    {
      this.showModal();

      console.log("Order to move", $(event.currentTarget).data("order-token"));

      let orderToken = $(event.currentTarget).data("order-token");
      
      Rails.ajax({
        type: "get",
        url: `/checkouts/get_checkout_order_move_modal/${orderToken}`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.order_edit_form;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }


    externalAdjustModal()
    {
      this.showModal();

      console.log("Order for external adjustment", $(event.currentTarget).data("order-token"));

      let orderToken = $(event.currentTarget).data("order-token");
      
      Rails.ajax({
        type: "get",
        url: `/orders/get_external_adjustment_modal/${orderToken}`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.modal_content;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }

    reorderFulfillmentModal()
    {
    this.showModal();

      console.log("Order to move", $(event.currentTarget).data("order-token"));

      let fulfillmentToken = $(event.currentTarget).data("fulfillment-token");
    
      Rails.ajax({
        type: "get",
        url: `/fulfillment_tasks/${fulfillmentToken}/get_fulfillment_reorder_modal`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.fulfillment_reorder_form;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });

    }

  
   
}