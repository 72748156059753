import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   
    
    
 
    initialize() {
      console.log("Freelancer stimulus controller");


      // var images = document.getElementsByClassName("status-cell");
      // console.log("IMages",images);

      // [...images].forEach(image => {
        
      //   let image_token = image.getAttribute("data-url")
      //   console.log('fakeImage: ', image_token);

      //   Rails.ajax({
      //     type: "post",
      //     url: "/checkouts/update_country",
      //     data: `checkout_token=${this.checkoutValue}&country=${event.target.value}`,
      //     success: function(data) {
      //       console.log(data.new_price);
      //       console.log(document.getElementById("total-checkout-price"));
      //       document.getElementById("total-checkout-price").textContent = data.new_price
      //       document.getElementById("country-shipping-information").innerHTML = data.message 
      //     },
      //   error: function(data)
      //   {
      //     console.log("Something went wrong!");
      //   }
      //   })

      // });

    }


    
   
    
   
}