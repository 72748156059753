import { Controller } from 'stimulus';
import Rails from "@rails/ujs";


require('datatables.net-bs')(window, $)
require('datatables.net-buttons-bs')(window, $)
require('datatables.net-buttons/js/buttons.colVis.js')(window, $)
require('datatables.net-buttons/js/buttons.html5.js')(window, $)
require('datatables.net-buttons/js/buttons.print.js')(window, $)
require('datatables.net-responsive-bs')(window, $)
require('datatables.net-select')(window, $)

export default class extends Controller {
   
   
   
    initialize() {
      console.log("Datatable stimulus controller");
      this.setUpDatatable()

    }

    setUpDatatable()
    {
      
      
        var datatable = $('#checkout-datatable').DataTable({
          "processing": true,
          "serverSide": true,
          "ajax": $('#checkout-datatable').data('source'),
          "pagingType": "full_numbers",
          "columns": [
                {"data": "checkout_token"},
                {"data": "images"},
                {"data": "user_name"},
                {"data": "payment_date"},
                {"data": "phone"},
                {"data": "status"},
                {"data": "more"},

              ],
          "pageLength": 100,
          "language": this.de_language()
          // "order": [[ 3, "desc" ]]
          // pagingType is optional, if you want full pagination controls.
          // Check dataTables documentation to learn more about
          // available options.
        });

        $('#adjustment-datatable').DataTable({
          "processing": true,
          "serverSide": true,
          "ajax": $('#adjustment-datatable').data('source'),
          "pagingType": "full_numbers",
          "columns": [
                {"data": "checkout_token"},
                {"data": "images"},
                {"data": "user_name"},
                {"data": "adjustment_creation_date"},
                {"data": "max_delivery_date"},
                {"data": "difficulty"},
                {"data": "status"},
                {"data": "employee"},
                {"data": "next_status"},
                {"data": "more"},

              ],
          "pageLength": 100,
          "language": this.de_language()
          // "order": [[ 3, "desc" ]]
          // pagingType is optional, if you want full pagination controls.
          // Check dataTables documentation to learn more about
          // available options.
        });


        $('#order-datatable').DataTable({
          "processing": true,
          "serverSide": true,
          "ajax": $('#order-datatable').data('source'),
          "pagingType": "full_numbers",
          "columns": [
                {"data": "token"},
                {"data": "additional_order_type"},
                {"data": "user_name"},
                {"data": "created_at"},
                {"data": "status"},
                {"data": "more"},

              ],
          "pageLength": 100,
          "language": this.de_language()
          // "order": [[ 3, "desc" ]]
          // pagingType is optional, if you want full pagination controls.
          // Check dataTables documentation to learn more about
          // available options.
        });

        datatable = $('#fulfilment-datatable').DataTable({
          "processing": true,
          "serverSide": true,
          "ajax": $('#fulfilment-datatable').data('source'),
          "pagingType": "full_numbers",
          "columns": [
                {"data": "fulfillment_token"},
                {"data": "user_name"},
                {"data": "sent_date"},
                {"data": "upload_date"},
                {"data": "provider"},
                {"data": "trackinglink"},
                {"data": "more"},

              ],
          "pageLength": 100,
          "language": this.de_language()
          // "order": [[ 3, "desc" ]]
          // pagingType is optional, if you want full pagination controls.
          // Check dataTables documentation to learn more about
          // available options.
        });

        
      
    }


    de_language()
    {
        return {
            "sSearch": "Suchen",
            "sEmptyTable":      "Keine Daten in der Tabelle vorhanden",
            "sInfo":            "_START_ bis _END_ von _TOTAL_ Einträgen",
            "sInfoEmpty":       "0 bis 0 von 0 Einträgen",
            "sInfoFiltered":    "(gefiltert von _MAX_ Einträgen)",
            "sInfoPostFix":     "",
            "sInfoThousands":   ".",
            "sLengthMenu":      "_MENU_ Einträge anzeigen",
            "sLoadingRecords":  "Wird geladen...",
            "sProcessing":      "Bitte warten...",
            "sSearch":          "Suchen",
            "sZeroRecords":     "Keine Einträge vorhanden.",
            "oPaginate": {
                "sFirst":       "Erste",
                "sPrevious":    "Zurück",
                "sNext":        "Nächste",
                "sLast":        "Letzte"
            },
            "oAria": {
                "sSortAscending":  ": aktivieren, um Spalte aufsteigend zu sortieren",
                "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
            },
            select: {
                    rows: {
                    _: '%d Zeilen ausgewählt',
                    0: 'Zum Auswählen auf eine Zeile klicken',
                    1: '1 Zeile ausgewählt'
                    }
            }
        };
    } 

    
  

  
   
}