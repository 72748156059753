import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   
   	
    static targets = ["frameSelection","currentFrameSelection"]
 
    initialize() {
      console.log("Video stimulus controller");


    }


    tabChanged(event)
    {
      console.log("Tab changed");
      console.log("Event",event.currentTarget);

      let asset_url_mp4 = event.currentTarget.getAttribute("data-asset-url-mp4");
      let asset_url_webm = event.currentTarget.getAttribute("data-asset-url-webm");
      let asset_url_poster = event.currentTarget.getAttribute("data-asset-poster");

      var video = document.getElementById('video1');
      var source1 = document.getElementById('source1');
      var source2 = document.getElementById('source2');

      
      

      video.pause();

      video.setAttribute('poster', asset_url_poster );
      source1.setAttribute('src', asset_url_mp4);
      source2.setAttribute('src', asset_url_webm);
  

      video.load();
      video.play();
  

    }
   
	
   
}