import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   
    static targets = ["modalContent"]

    static values = { adjustment: String, name: String, hometown: String, employee: String, checkout: String, conversationId: String, orderCount: Number, isDigital: Boolean }
   
    initialize() {
      console.log("Quick reply controller");
      console.log(this.adjustmentValue);


      //Scroll message view to bototm
      var element = document.getElementById("messageView");
      element.scrollTop = element.scrollHeight;

    }


    confirmWithEyes()
    {

      var messages = [];
      if(!this.isDigitalValue)
      {
        messages.push(`Super, ich habe vermerkt, dass Du gerne die Variante 1x mal mit Augen zugesendet haben möchtest 🤗 Bestätige jetzt bitte unter diesem Link https://www.miroar.de/confirm/${this.checkoutValue} noch Deine Versandadresse, gerne kannst Du sie auch noch anpassen. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
        messages.push(`Alles klar, dann geben wir die Variante 1x mit Augen in den Druck, sobald Du Deine Versandadresse bestätigt hast 🤗 Klicke dazu einfach auf nachfolgenden Link: https://www.miroar.de/confirm/${this.checkoutValue}. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
        messages.push(`Okay perfekt, dann geben wir die Variante 1x mit Augen in den Druck, sobald Du Deine Versandadresse nochmal für uns bestätigt hast 🤗 Klicke dazu einfach auf nachfolgenden Link: https://www.miroar.de/confirm/${this.checkoutValue}. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
      }
      else
      {
        messages.push(`Super, ich habe vermerkt, dass Du gerne die Variante 1x mal mit Augen zugesendet haben möchtest 🤗 Die digitale Datei sende ich Dir gleich zu.`);
        messages.push(`Alles klar, dann senden wir die Variante mit Augen zu 🤗 Gib mir einen Moment, dann bereite ich die digitale Datei für dich vor.`);
        messages.push(`Okay perfekt, dann bereiten wir eben die digitale Variante 1x mit Augen für Dich vor 🤗 Ich lasse Dir gleich einen Link zum Download zukommen.`);
      }
     
     
      //Hide the adjustment text field
      document.getElementById("internal_note").style.display = "none";
      document.getElementById("customer_message").style.width = "100%";

      if(this.orderCountValue <= 1)
      {
         this.displayFileSelectionController();

     
          document.getElementById("internal_note").value = "1x mit Augen \n";
          document.getElementById("type").value = "confirmation";
          document.getElementById("customer_message").value = messages[this.randomInteger(0,messages.length-1)];
      }
      else
      {
           //The checkout has more orders, use the modal approach 
        this.showModal();
        this.multipleOrderConfirmationModal(messages)
      }
      // messages.push("Super, dann geben wir die Variante 1x mit Augen in den Druck 🤗 Wir melden uns dann wieder bei Dir per E-Mail, sobald wir Dein Bild gedruckt und verschickt haben und von DHL einen Trackinglink bekommen haben 🤗")
      // messages.push("Alles klar, dann geben wir die Variante 1x mit Augen in den Druck 🤗 Wir melden uns dann wieder bei Dir unter Deiner E-Mail Adresse, sobald wir Dein Bild gedruckt und verschickt haben und von DHL einen Trackinglink bekommen haben 🤗")
      // messages.push("Okay perfekt, dann geben wir die Variante 1x mit Augen in den Druck 🤗 sobald wir Dein Bild gedruckt und verschickt haben und von DHL einen Trackinglink bekommen haben, melden wir uns wieder bei Dir unter Deiner E-Mail Adresse  🤗")

     
    }

    randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    confirmWithoutEyes()
    {


      var messages = [];
      if(!this.isDigitalValue)
      {
        messages.push(`Super, ich habe vermerkt, dass Du gerne die Variante 1x ohne Augen zugesendet haben möchtest 🤗 Bestätige jetzt bitte unter diesem Link https://www.miroar.de/confirm/${this.checkoutValue} noch Deine Versandadresse, gerne kannst Du sie auch noch anpassen. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
        messages.push(`Alles klar, dann geben wir die Variante 1x ohne Augen in den Druck, sobald Du Deine Versandadresse bestätigt hast 🤗 Klicke dazu einfach auf nachfolgenden Link: https://www.miroar.de/confirm/${this.checkoutValue}. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
        messages.push(`Okay perfekt, dann geben wir die Variante 1x ohne Augen in den Druck, sobald Du Deine Versandadresse nochmal für uns bestätigt hast 🤗 Klicke dazu einfach auf nachfolgenden Link: https://www.miroar.de/confirm/${this.checkoutValue}. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
      }
      else
      {
        messages.push(`Super, ich habe vermerkt, dass Du gerne die Variante ohne Augen zugesendet haben möchtest 🤗 Die digitale Datei sende ich Dir gleich zu.`);
        messages.push(`Alles klar, dann senden wir die Variante ohne Augen zu 🤗 Gib mir einen Moment, dann bereite ich die digitale Datei für dich vor.`);
        messages.push(`Okay perfekt, dann bereiten wir eben die digitale Variante ohne Augen für Dich vor 🤗 Ich lasse Dir gleich einen Link zum Download zukommen.`);
      }
      document.getElementById("internal_note").style.display = "none";
      document.getElementById("customer_message").style.width = "100%";

      if(this.orderCountValue <= 1)
      {
        this.displayFileSelectionController();
        document.getElementById("internal_note").value = "1x OHNE Augen \n"
        document.getElementById("type").value = "confirmation"
        document.getElementById("customer_message").value = messages[this.randomInteger(0,messages.length-1)];
      }
      else
      {
           //The checkout has more orders, use the modal approach 
        this.showModal();
        this.multipleOrderConfirmationModal(messages)
      }


        
      

      
      
      // messages.push("Super, dann geben wir die Variante 1x ohne Augen in den Druck 🤗 Wir melden uns dann wieder bei Dir per E-Mail, sobald wir Dein Bild gedruckt und verschickt haben und von DHL einen Trackinglink bekommen haben 🤗")
      // messages.push("Alles klar, dann geben wir die Variante 1x ohne Augen in den Druck 🤗 Wir melden uns dann wieder bei Dir unter Deiner E-Mail Adresse, sobald wir Dein Bild gedruckt und verschickt haben und von DHL einen Trackinglink bekommen haben 🤗")
      // messages.push("Okay perfekt, dann geben wir die Variante 1x ohne Augen in den Druck 🤗 sobald wir Dein Bild gedruckt und verschickt haben und von DHL einen Trackinglink bekommen haben, melden wir uns wieder bei Dir unter Deiner E-Mail Adresse  🤗")


    }

    confirmLastVersion()
    {

      var messages = [];
      if(!this.isDigitalValue)
      {
        messages.push(`Super, ich habe vermerkt, dass Du gerne die letzte Variante zugesendet haben möchtest 🤗 Bestätige jetzt bitte unter diesem Link https://www.miroar.de/confirm/${this.checkoutValue} noch Deine Versandadresse, gerne kannst Du sie auch noch anpassen. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
        messages.push(`Alles klar, dann geben wir die letzte Variante in den Druck, sobald Du Deine Versandadresse bestätigt hast 🤗 Klicke dazu einfach auf nachfolgenden Link: https://www.miroar.de/confirm/${this.checkoutValue}. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
        messages.push(`Okay perfekt, dann geben wir die letzte Variante in den Druck, sobald Du Deine Versandadresse nochmal für uns bestätigt hast 🤗 Klicke dazu einfach auf nachfolgenden Link: https://www.miroar.de/confirm/${this.checkoutValue}. Anschließend geht das Bild schnellstmöglich auf den Weg zu Dir 🤗`)
      }
      else
      {
         messages.push(`Super, ich habe vermerkt, dass Du gerne die letzte Version zugesendet haben möchtest 🤗 Die digitale Datei sende ich Dir gleich zu.`)
         messages.push(`Alles klar, dann senden wir die letzte Version zu 🤗 Gib mir einen Moment, dann bereite ich die digitale Datei für dich vor.`)
         messages.push(`Okay perfekt, dann bereiten wir eben die digitale Variante der letzten Version für Dich vor 🤗 Ich lasse Dir gleich einen Link zum Download zukommen.`)
      }

      document.getElementById("internal_note").style.display = "none";
      document.getElementById("customer_message").style.width = "100%";

      if(this.orderCountValue <= 1)
      {
          this.displayFileSelectionController()
          document.getElementById("internal_note").value = "1x letzte Version \n"
          document.getElementById("type").value = "confirmation"
          document.getElementById("customer_message").value = messages[this.randomInteger(0,messages.length-1)]
      }
      else
      {

         //The checkout has more orders, use the modal approach 
        this.showModal();
        this.multipleOrderConfirmationModal(messages)

      }
      


    }



    adjustmentRequest()
    {
      

      

      var messages = [];
      messages.push("Lieben Dank für Dein Feedback 🤗 Sehr gerne überarbeiten wir Deinen Entwurf entsprechend 💪🏻 Wir melden uns dann wieder hier auf WhatsApp bei Dir, sobald wir den Entwurf fertig überarbeitet haben 🤗")
      messages.push("Lieben Dank für Deine Rückmeldung 🤗 Das können wir sehr gerne für Dich umsetzen 💪🏻 Wir melden uns dann wieder hier auf WhatsApp bei Dir, sobald wir den Entwurf fertig überarbeitet haben 🤗")
      messages.push("Danke, Dein Feedback hilft uns sehr weiter 🤗 Sehr gerne passen wir den Entwurf entsprechend für Dich an 💪🏻 Wir melden uns dann wieder hier auf WhatsApp bei Dir, sobald wir den Entwurf fertig überarbeitet haben 🤗")

      if(this.orderCountValue <= 1)
      {
         document.getElementById("internal_note").style.display = "block";
         document.getElementById("customer_message").style.width = "50%";
         document.getElementById("internal_note").value = this.adjustmentValue;
         document.getElementById("type").value = "adjustmentrequest"
         document.getElementById("customer_message").value = messages[this.randomInteger(0,messages.length-1)]
      }
      else
      {
          //The checkout has more orders, use the modal approach 
          this.showModal();

          console.log("Adjustment request");
          console.log(this.conversationIdValue);
          
          Rails.ajax({
            type: "get",
            url: `/orders/get_external_adjustment_modal_quickchat/${this.checkoutValue}?conversation_id=${this.conversationIdValue}`,
            data: null,
            success: function(result) { 
                console.log("Success", this.modalContentTarget);
                console.log("result",result);
                this.modalContentTarget.style.display = "block";
                this.modalContentTarget.innerHTML = result.modal_content;
                document.getElementById("customer_message_modal").innerHTML = messages[this.randomInteger(0,messages.length-1)]
                document.getElementsByClassName("adjustment-note")[0].innerHTML = this.adjustmentValue;
                //document.getElementById("internal_note").value = this.adjustmentValue;
            }.bind(this),
            error: function(data)
            {
              console.log("Something went wrong!");
            }
          });
      }

    

    }

    //This method display the order confirmation screen
    multipleOrderConfirmationModal(messages)
    {
      Rails.ajax({
            type: "get",
            url: `/orders/get_order_confirmation_modal_quickchat/${this.checkoutValue}?conversation_id=${this.conversationIdValue}`,
            data: null,
            success: function(result) { 
                console.log("Success", this.modalContentTarget);
                console.log("result",result);
                this.modalContentTarget.style.display = "block";
                this.modalContentTarget.innerHTML = result.modal_content;
                 document.getElementById("customer_message_modal").innerHTML = messages[this.randomInteger(0,messages.length-1)]
            }.bind(this),
            error: function(data)
            {
              console.log("Something went wrong!");
            }
        });
    }

    //This method displays the option to select the printing files
    displayFileSelectionController()
    {

      console.log("Display File Selection Controller");

      Rails.ajax({
        type: "get",
        url: `/display_printing_files_for_selection/${this.checkoutValue}`,
        data: null,
        success: function(result) { 
            console.log("Success", this.file_selection);

            var containerDiv = document.createElement("div");
            containerDiv.id = "fileSelectionDiv";
            containerDiv.style.width = "100%";
            containerDiv.innerHTML = result.file_selection;
            document.getElementById("messageView").appendChild(containerDiv);
            //Scroll message view to bottom
            var element = document.getElementById("messageView");
            element.scrollTop = element.scrollHeight;


        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }

    changeCheckout(event)
    {
      console.log("Change Checkout quickreply");
      let checkoutToken =  document.getElementById("checkout_token").value
      console.log("Change Checkout quickreply", checkoutToken);

      var url = window.location.href.split('?')[0];
      url = url + "?checkout_token=" + checkoutToken;
      console.log("URl",url);
      window.open(url, "_self");

    }

    fileSelectionChanged(event)
    {
      console.log("File selection changed");
      console.log(event.target.value);
      console.log(event.target.name);

      document.getElementById(event.target.name).value = event.target.value;

    }

    easyAdjustmentRequest()
    {
       document.getElementById("internal_note").style.display = "block";
       document.getElementById("customer_message").style.width = "50%";
       document.getElementById("internal_note").value = this.adjustmentValue;
      document.getElementById("type").value = "easyadjustmentrequest"
      var messages = [];
      messages.push("Lieben Dank für Dein Feedback 🤗 Sehr gerne überarbeiten wir Deinen Entwurf entsprechend 💪🏻 Wir melden uns dann wieder hier auf WhatsApp bei Dir, sobald wir den Entwurf fertig überarbeitet haben 🤗")
      messages.push("Lieben Dank für Dein Rückmeldung 🤗 Das können wir sehr gerne für Dich umsetzen 💪🏻 Wir melden uns dann wieder hier auf WhatsApp bei Dir, sobald wir den Entwurf fertig überarbeitet haben 🤗")
      messages.push("Danke, Dein Feedback hilft uns sehr weiter 🤗 Sehr gerne passen wir den Entwurf entsprechend für Dich an 💪🏻 Wir melden uns dann wieder hier auf WhatsApp bei Dir, sobald wir den Entwurf fertig überarbeitet haben 🤗")
      document.getElementById("customer_message").value = messages[this.randomInteger(0,messages.length-1)]

    }

    setConfirmText(event)
    {
      console.log(event.target.dataset.textArea);
      document.getElementById(event.target.dataset.textArea).value = event.target.dataset.text;
    }

 

    showModal()
    {
      
      document.getElementById("modal_background").classList.remove("hidden");
      setTimeout(function(){
          document.getElementById("modal").classList.add("slide_in");
      }, 100);
    }

    addSmiley(event)
    {
      console.log(event.target.innerHTML);
      //Add the smiley to the customer message text
      document.getElementById("customer_message").value += event.target.innerHTML

    }

    hometown()
    {
      //Add the hometown to the customer message text
      document.getElementById("customer_message").value += this.hometownValue;

    }

    goodMorning()
    {
       document.getElementById("customer_message").value = "Guten Morgen " + this.nameValue + " ☀️ " + document.getElementById("customer_message").value
    }

    hello()
    {
       document.getElementById("customer_message").value = "Hallo " + this.nameValue + " 👋 " + document.getElementById("customer_message").value
    }


    trackingLink()
    {
      document.getElementById("customer_message").value += " Alles klar, wir melden uns dann wieder bei Dir, sobald wir Dein Bild gedruckt & versendet haben und von DHL einen Trackinglink erhalten haben."
    }

    shipmentTime()
    {
      document.getElementById("customer_message").value += " Dein Bild wird wahrscheinlich noch heute, spätestens morgen versendet 🤗 Danach benötigt DHL üblicherweise 1-3 Werktage, eher das Bild bei Dir in  " + this.hometownValue + " ist.";
    }

    weekend()
    {
     
      //Add the smiley to the customer message text
      document.getElementById("customer_message").value += " Ich wünsche Dir ein ganz schönes Wochenende 🤗"

    }

    thanksForYourOrder()
    {
        document.getElementById("customer_message").value += " Sehr gerne, wir sagen ebenfalls Danke für Deine Bestellung bei uns 🙏 Melde Dich jederzeit gerne bei uns, wenn wir mal wieder ein Line Art Bild für Dich zeichnen dürfen 🤗 Ganz liebe Grüße nach " + this.hometownValue + " ";
    }

    signature()
    {
     
      //Add the smiley to the customer message text
      document.getElementById("customer_message").value += "Liebe Grüße, " + this.employeeValue

    }

    generateCancellationLink()
    {
      //Add a cancellation link to the customer message field
      document.getElementById("customer_message").value += "Alles klar "+ this.nameValue + ", es tut uns sehr leid, dass wir Dich mit unserer Arbeit nicht überzeugen konnten 😢 Aber genau dafür haben wir ja unsere Geld-zurück-Garantie, die Du unter diesem Link hier in Anspruch nehmen kannst: " + `https://www.miroar.de/cancel/${this.checkoutValue}` + " 🤗"
    }

    closeModal()
    {
      console.log("Close modal");
      document.getElementById("modal").classList.remove("slide_in");
      setTimeout(function(){
          document.getElementById("modal_background").classList.add("hidden");
          this.modalContentTarget.innerHTML = "";
      }, 100);
    }




    
  
   
}