// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'
import Rails from "@rails/ujs";
// you can also import a translation file
import { German } from 'flatpickr/dist/l10n/de.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {


  static values = { token: String }


  initialize() {

    this.config = {
      locale: German,
      dateFormat: "d.m.Y", 
      disableMobile: "true", 
      enable: [
        function(date) {
            const today = new Date()
            const tomorrow = new Date(today)
            tomorrow.setDate(tomorrow.getDate() + 1)
            tomorrow.setUTCHours(-2,0,0,0);
            console.log(date);
            console.log(tomorrow);
            return (date >= tomorrow);
        }
      ]
    };

    console.log("Checkout test date picker", this);
    console.log("Locale",German);
   
  }


  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    console.log('the callback returns the selected dates', selectedDates);
    console.log('but returns it also as a string', dateStr);
    console.log('and the flatpickr instance', instance);
    console.log('and the flatpickr instance', this.tokenValue);

    Rails.ajax({
        type: "post",
        url: "/user_change_max_delivery_date",
        data: `checkout_token=${this.tokenValue}&max_delivery_date=${selectedDates[0]}`,
        success: function(result) { 
          console.log("REsult",result)
           $(".handling_options").first().replaceWith(result.handling_options);
           $(".handling_options").removeClass("hidden");
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });


    
  }

  
}