import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   

    static targets = ["modalContent"]
  
    initialize() {
      console.log("Admin Coupon stimulus controller");

       
    }


    addCoupon(event)
    {
      console.log("Add coupon");
      this.showModal();
      Rails.ajax({
        type: "get",
        url: `/coupons/get_coupon_create_modal`,
        data: null,
        success: function(result) { 
            console.log("Success", this.modalContentTarget);
            console.log("result",result);
            this.modalContentTarget.style.display = "block";
            this.modalContentTarget.innerHTML = result.coupon_create_form;
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      });
    }


    showModal()
    {
      this.modalContentTarget.style.display = "none";
      document.getElementById("modal_background").classList.remove("hidden");
      setTimeout(function(){
          document.getElementById("modal").classList.add("slide_in");
      }, 100);
    }

    closeModal()
    {
      console.log("Close modal");
      document.getElementById("modal").classList.remove("slide_in");
      setTimeout(function(){
          document.getElementById("modal_background").classList.add("hidden");
      }, 100);
    }




}