// ./controllers/flatpickr_controller.js
// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from 'stimulus-flatpickr'
import Rails from "@rails/ujs";
// you can also import a translation file
import { German } from 'flatpickr/dist/l10n/de.js'

// import a theme (could be in your main CSS entry too...)
import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {


  static values = { checkout: String }



  initialize() {

    this.config = {
      dateFormat: "Y-m-d"
    };

    console.log("Test", this);
   
  }


  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change(selectedDates, dateStr, instance) {
    console.log('the callback returns the selected dates', selectedDates);
    console.log('but returns it also as a string', dateStr);
    console.log('and the flatpickr instance', instance);
    console.log("This checkout value",this.checkoutValue);
    //Do not call the update callback in case we just want do generate a revenue report

    if(instance.element.name != "start_date" && instance.element.name != "end_date" && this.checkoutValue != "")
    {
      Rails.ajax({
        type: "post",
        url: `/checkouts/${this.checkoutValue}/update_max_delivery_date`,
        data: `date=${selectedDates[0]}`,
        success: function(data) {
        console.log("Success");
      },
      error: function(data)
      {
        console.log("Could not save the date");
      }
      });
    }

    
  }

  resetDate()
  {
    console.log("Reset date");
    if(instance.element.name != "start_date" && instance.element.name != "end_date" && this.checkoutValue != "")
    {
      Rails.ajax({
          type: "post",
          url: `/checkouts/${this.checkoutValue}/update_max_delivery_date`,
          data: `date=`,
          success: function(data) {
          console.log("Success");
          document.getElementById("checkout_max_delivery_date").value = "";
        },
        error: function(data)
        {
          console.log("Could not save the date");
        }
        })
    }
  }
}