import { Controller } from 'stimulus';
import Rails from "@rails/ujs";



export default class extends Controller {
   
   static targets = ["frameSelection","currentFrameSelection"]

   static values = { token: String }
   
    initialize() {
      console.log("Checkout stimulus controller");

    }


     showFrameSelection(event)
    {


      //Hide all other open dialoges
      this.frameSelectionTargets.forEach((frameSelection) => {frameSelection.style.display = "none"});

      console.log(event);
      console.log($(event.currentTarget).data("order-token-value"));
      var currentToken = $(event.currentTarget).data("order-token-value");
      console.log("Show frame selection", this.frameSelectionTargets);
      var currentTarget = this.frameSelectionTargets.filter((frameSelection)=> $(frameSelection).data("order-token-value") == currentToken)[0];
      currentTarget.style.display = "block";

    }

    changeFrame(event)
    {
      
      console.log("Change frame2", $(event.currentTarget).children(".frame_select_heading"));



      var currentToken = $(event.currentTarget).parent(".frame_select_content").data("order-token-value");
  

      this.currentFrameSelectionTargets.filter((frameSelection)=> $(frameSelection).data("order-token-value") == currentToken)[0].innerHTML = $(event.currentTarget).find(".frame_select_heading")[0].innerHTML;
      //Update the selection
      $(event.currentTarget).parent().children(".frame_select_row").removeClass("frame_selected");
      $(event.currentTarget).addClass("frame_selected");
      var newFrameValue = $(event.currentTarget).children(".frame_select_heading").data("frame-value");
      console.log("New frame value", newFrameValue);
      $('#order_frame').val(newFrameValue);
      this.frameSelectionTarget.style.display = "none";


      //Change preview
      $("#frame_preview").removeClass("black_frame white_frame natural_frame").addClass(newFrameValue);


      console.log();

      var frameBackground = $(event.currentTarget).parents(".new_cart_item_wrapper").find(".cart_v2_frame_background")
      frameBackground.removeClass("black_frame white_frame natural_frame").addClass(newFrameValue);


      //Hide all other open dialoges
      this.frameSelectionTargets.forEach((frameSelection) => {frameSelection.style.display = "none"})

      
      console.log("Update order token", currentToken);

      Rails.ajax({
        type: "post",
        url: "/orders/update_frame",
        data: `frame=${newFrameValue}&token=${currentToken}`,
        success: function(result) { 
            console.log("Frame successfully changed!",result.checkout_conclusion);
            $(".checkout_conclusion_wrapper").first().replaceWith(result.checkout_conclusion);
            $(".checkout_gift_wrapper").first().replaceWith(result.checkout_gift);
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      })
    }


    redeemCode(event)
    {
      console.log("Redeem code");

      var checkoutToken = $(event.currentTarget).data("checkout-token");
      var code = $('#code').val();

      console.log("Code",code);


      Rails.ajax({
        type: "post",
        url: "/redeem_code",
        data: `code_value=${code}&checkout_token=${checkoutToken}`,
        success: function(result) { 
          console.log("Redeem result",result);
            $(".checkout_conclusion_wrapper").first().replaceWith(result.checkout_conclusion);
            $(".coupon_feedback_wrapper").show();
            if(result.redeemed_successful)
            {              
              $(".coupon_feedback_wrapper").text("🎉 Code erfolgreich eingelöst. 🎉");
              $(".coupon_feedback_wrapper").css("color","#00a590");
            }
            else
            {
              $(".coupon_feedback_wrapper").text("Der Code ist leider ungültig.");
              $(".coupon_feedback_wrapper").css("color","red");
            }
            $("#total-checkout-price").text(result.new_price);
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      })

    }

    //This method shows the coupon field

    showCode(event)
    {
      console.log("Show code field");

      $("#voucher-form").removeClass("hidden");
      $(event.target).addClass("hidden");

     
    }


    //This method enables the user to switch between different shipping options
    selectHandlingOption(event)
    {
      console.log("Switch between shipping options");
      $(event.currentTarget).parent(".vertical_select_wrapper").children(".vertical_select_option").removeClass("selected");
      $(event.currentTarget).parent(".vertical_select_wrapper").find(".option_select_indicator").removeClass("selected");
      $(event.currentTarget).find(".option_select_indicator").addClass("selected");
      $(event.currentTarget).addClass("selected");


      let priority = $(event.currentTarget).data("priority")

      console.log("priority",);

      Rails.ajax({
        type: "post",
        url: "/user_change_handling_option",
        data: `checkout_token=${this.tokenValue}&priority=${priority}`,
        success: function(result) { 
          console.log("Change handling option result",result);
          $(".checkout_conclusion_wrapper").first().replaceWith(result.checkout_conclusion);
          $("#total-checkout-price").text(result.new_price);
           
        }.bind(this),
        error: function(data)
        {
          console.log("Something went wrong!");
        }
      })

      

    }

  
   
}