
$( document ).on('turbolinks:load', function() {
	console.log("Webflow turbo");
	Webflow.destroy();
	Webflow.ready();
	Webflow.require('ix2').init();
	
	try {
	    Trustpilot.Modules.WidgetManagement.findAndApplyWidgets();
	} catch (ex) {
	   
	}
	
	
 
});